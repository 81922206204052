import React from 'react'
import { Typography, Grid, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  padded: {
    paddingTop: theme.spacing(2),
  },
  mail: {
    height: 30,
    fontWeight: 'bold',
  }
}));

export interface CardProps {
  name: string;
  surname: string;
  title: string;
  email?: string;
}

export const ContactCard = ({ name, surname, title, email }: CardProps) => {
  const { uppercase, padded, mail } = useStyles({})

  return (
    <Grid container wrap='nowrap' justify='center' alignItems='center' direction='column'>
      <Grid item>
        <Typography variant='h6' className={uppercase}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6' className={uppercase}>
          {surname}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align='center' variant='body2'>
          {title}
        </Typography>
      </Grid>
      <Grid item className={padded}>
        <Typography className={mail}>
          {email}
        </Typography>
      </Grid>
    </Grid>
  )
}
