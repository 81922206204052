import React, { ReactNode, ChangeEvent as ReactChangeEvent } from 'react'
import {
  Select,
  SelectProps,
  FormHelperText,
  InputLabel,
  FormControl,
} from '@material-ui/core'

interface OwnProps {
  name: string;
  label: string;
  size?: 'small' | 'medium';
  children?: any;
  noHelperText?: boolean;
  helperText?: ReactNode;
  error?: boolean;
}

export type ChangeEvent = ReactChangeEvent<{ name?: string, value: unknown }>

export type SelectFieldProps = OwnProps & SelectProps;

export const SelectField = ({
  label,
  children,
  required,
  disabled,
  error,
  helperText,
  variant = 'filled',
  fullWidth = true,
  size,
  name,
  noHelperText = false,
  value,
  ...selectProps
}: SelectFieldProps) => {
  const sharedProps = {
    error,
    required,
    disabled,
    variant,
  }

  return (
    <FormControl size={size} fullWidth={fullWidth} {...sharedProps}>
      <InputLabel
        id={`select-${name}`}
        {...sharedProps}
      >
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        labelId={`select-${name}`}
        value={value || ''}
        {...sharedProps}
        {...selectProps}
      >
        {children}
      </Select>
      { !noHelperText &&
        <FormHelperText {...sharedProps}>
          {helperText}
        </FormHelperText>
      }
    </FormControl>
  )
}
