import React, { ChangeEvent } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core'

import { City, CityId, FindCities } from 'types'

interface CitySelectionProps {
  cities?: City[];
  selectedCity?: CityId;
  onSelectCity: (id: CityId) => void;
}
  
  const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    }
}));

const VISIBLE_CITY_LIST = gql`
  query {
      cities (
        sort: "name:asc",
        where: {is_visible: true }
        )
      {  id, name, coordinates, hourFrom, hourTo }
}
`
const ALL_CITY_LIST = gql`
{
  cities { id, name, coordinates, hourFrom, hourTo }
}
`

export const useQueryVisibleCities = () => {
  return useQuery<FindCities>(VISIBLE_CITY_LIST, {variables: {is_visible: true}});
}

export const useQueryAllCities = () => {
  return useQuery<FindCities>(ALL_CITY_LIST, {variables: {is_visible: true}});
}

export const CitySelection = (props: CitySelectionProps) => {
    const { cities, selectedCity, onSelectCity } = props;
    const { t } = useTranslation();
    const classes = useStyles(props);
    return (
      <FormControl variant='filled' className={classes.formControl}>
        <InputLabel variant='filled' htmlFor='city'>{t('admin.city')}</InputLabel>
        <Select
          value={selectedCity || ''}
          variant='filled'
          onChange={(e: ChangeEvent<{ name?: string, value: unknown }>) => {
            onSelectCity(parseInt(e.target.value as string, 10) as CityId)
          }}
          inputProps={{
            id: 'city',
          }}
         >
           <MenuItem value='' />
            {
              cities && cities.map(({ id, name }) =>
                <MenuItem key={id} value={id}>{name}</MenuItem>)
            }
        </Select>
    </FormControl>
    )
}

export const CitySelectionWithAllCityQuerying = (props: Pick<CitySelectionProps, 'onSelectCity' | 'selectedCity'>) => {
  const { data } = useQueryAllCities();
  return <CitySelection {...props} cities={data && data.cities}/>
}
