/**
 * Google maps accepts locations of LatLngLiteral
 * and LatLng types. We store coordinates in a string.
 * This utility converts it from ex. '39.0293211,125.6020262' to
 * { lat: 39.0293211, lng: 125.6020262 }.
 */

export interface LatLngLiteral {
    lat: number;
    lng: number;
}

export const coordinateStringToLatLngLiteral = (coordinateString: string): LatLngLiteral => {
    return {
        lat: parseFloat(coordinateString.split(',')[0]),
        lng: parseFloat(coordinateString.split(',')[1])
    }
}
