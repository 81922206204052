import React from 'react'

import { useTranslation } from 'react-i18next'
import { Typography, Grid, Box, makeStyles} from '@material-ui/core'

import { Layout } from '../components/layouts/main-layout'
import { ContactCard, CardProps } from '../components/contact-card'
import { ContactForm } from '../components/contact-form'
import { Contact } from 'components/logos/contact'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  svgContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1000,
    opacity: 0.15,
    left: 0,
    [theme.breakpoints.down('md')]: {
      top: 130,
    },
  }
}));



export default () => {
  const { t } = useTranslation()
  const { bold, svgContainer }= useStyles({})
  const renderCards = () => cards.map((card: CardProps) => (
    <Grid key={card.name} item>
      <ContactCard {...card} />
    </Grid>
  ))

  const cards = [
    {
      name: 'Viktorija',
      surname: 'Mamontovaitė',
      title: t('contacts.pm'),
      email: 'viktorija@gmd.lt',
    },
    {
      name: 'Jogaila',
      surname: 'Ivašauskas',
      title: t('contacts.coordinator'),
      email: 'jogaila@gmd.lt',
    },
    {
      name: 'Andrius',
      surname: 'Mamontovas',
      title: t('contacts.fire-starter'),
    },
  ]

  return (
    <ApolloProvider client={new ApolloClient({
      uri: `${process.env.GATSBY_API_URL}/graphql`,
    })}>
      <Layout pageName={t('page.contacts')} pageTitle={t('contacts.title')} withCity={false}>
        <div className={svgContainer}>
          <Contact />
        </div>
        <Box p={2}>
          <Box pb={2}>
            <Typography paragraph className={bold} align='center'>
              {t('contacts.help')}
            </Typography>
          </Box>
          <Grid
            container
            justify='center'
            spacing={4}
          >
            <Grid item xs={12} md={9} container direction='column'>
              <ContactForm />
            </Grid>

            <Grid item xs={12} md={3} container>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='center'
                alignItems='center'
              >
                {renderCards()}
              </Grid>
            </Grid>
          </Grid>
          <Box pt={2}>
            <Grid container alignItems='center' justify='center' spacing={1}>
              <Grid item>
                <Typography align='center'>
                  {t('contacts.other-questions')}{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={bold} align='center'>
                  koordinavimas@gmd.lt
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Layout>
    </ApolloProvider>
  )
}
