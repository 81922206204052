interface UrlParams {
  [key: string]: number | string
}

type GetEnvURL = (endpoint: string) => string
export const getEnvURL: GetEnvURL = (endpoint) =>
  `${process.env.GATSBY_API_URL}${endpoint}`


type CreateURL = (url: string, params: UrlParams) => string
export const createURL: CreateURL = (url, params) =>
  Object.keys(params).reduce(
    (url, key) => url.replace(`:${key}`, params[key].toString()),
    url,
  )


type UrlHasProtocol = (url: string) => boolean
export const urlHasProtocol: UrlHasProtocol =
  (url) => url.includes('http') || url.includes('https')

type FixUrlProtocol = (url: string) => string
export const fixUrlProtocol: FixUrlProtocol =
  (url) => urlHasProtocol(url)
    ? url
    : `http://${url}`

type GetPlainUrl = (url: string) => string
export const getPlainUrl: GetPlainUrl =
  (url) => !urlHasProtocol(url)
    ? url
    : url.replace(/(^\w+:|^)\/\//, '')
